import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
	ADULT_BASE_FRAME_NAMES,
	BASE_FRAME_NAMES,
	DEFAULT_BASE_FRAME_SHAPE,
	KIDS_BASE_FRAME_NAMES,
	NET_NEW_ACETATE,
} from '@constants';
import { Button, Heading, Modal, Tabs } from '@components';
import { useHasMounted, useLocalStorage } from '@utils/hooks';
import styles from './FrameShapeSelect.module.scss';

const FrameShapeOption = dynamic(() => import('./FrameShapeOption'));

type FrameShapeSelectProps = {
	callback: (f: (typeof BASE_FRAME_NAMES)[number]) => void;
	buttonProps?: object;
	available?: boolean;
	variantsAvailable?: Set<string>;
	withChevron?: boolean;
	dataTags?: object;
};

const FrameShapeSelect = ({
	callback,
	buttonProps,
	available = true,
	variantsAvailable = new Set(BASE_FRAME_NAMES),
	withChevron = false,
	dataTags,
}: FrameShapeSelectProps) => {
	const isNetNewAcetate = useFeatureIsOn('is-net-new-acetate');
	const updateAdultFrames = isNetNewAcetate
		? ADULT_BASE_FRAME_NAMES
		: ADULT_BASE_FRAME_NAMES.filter(frame => !NET_NEW_ACETATE.includes(frame as (typeof NET_NEW_ACETATE)[number]));
	const hasMounted = useHasMounted();
	const [activeFrame, setActiveFrame] = useLocalStorage<(typeof BASE_FRAME_NAMES)[number]>(
		'activeFrame',
		DEFAULT_BASE_FRAME_SHAPE
	);
	// If it's Twain or Larkin default to adults on first render, otherwise default to the correct tab - after first render the previously selected tab stays active
	const [activeTab, setActiveTab] = useState(
		!['Twain', 'Larkin'].includes(activeFrame) && (KIDS_BASE_FRAME_NAMES as unknown as string[]).includes(activeFrame)
			? 'kids'
			: 'adults'
	);

	const { locale } = useRouter();
	const isGB = locale === 'en-GB';

	useEffect(() => {
		// When locale changes, reset the active tab to adults
		setActiveTab('adults');
	}, [locale]);

	function getStyles(baseFrame: string): string {
		if (activeFrame === baseFrame) {
			return styles.selected;
		}
		if (available && variantsAvailable.has(baseFrame)) {
			return styles.option;
		}
		return styles.unavailableOption;
	}

	function sortByColumns(arr: string[], columns: number) {
		const rows = Math.ceil(arr.length / columns);
		const sorted = Array(arr.length);

		for (let i = 0; i < arr.length; i++) {
			const row = i % rows;
			const col = Math.floor(i / rows);
			sorted[row * columns + col] = arr[i];
		}

		return sorted;
	}

	const adultFrames = isGB
		? sortByColumns([...updateAdultFrames, ...KIDS_BASE_FRAME_NAMES].sort(), 2)
		: sortByColumns([...updateAdultFrames], 2);

	return (
		<Modal>
			<Modal.Trigger asChild>
				<Button extraClasses={styles.frameButton} color='white' {...buttonProps} withChevron={withChevron} {...dataTags}>
					{hasMounted ? `The ${activeFrame}` : 'Select Frame Shape'}
				</Button>
			</Modal.Trigger>
			<Modal.Content className={styles.content}>
				<Heading tag='h5'>Select Base Frame</Heading>
				<Tabs
					value={activeTab}
					onValueChange={value => setActiveTab(value)}
					activationMode='manual'
					className={styles.tabs}
				>
					<Tabs.List>
						<Tabs.Trigger value='adults'>
							<span>Adult Frames</span>
						</Tabs.Trigger>
						{!isGB && (
							<Tabs.Trigger value='kids'>
								<span>Kids Frames</span>
							</Tabs.Trigger>
						)}
					</Tabs.List>
					<Tabs.Content value='adults' removeDefaultStyles className={styles['tab-content']}>
						<div className={styles.grid}>
							{adultFrames.map(bf => (
								<Modal.Close key={`select-${bf}-adult-shape`} asChild>
									<FrameShapeOption
										frame={bf}
										onClick={callback}
										activeFrame={activeFrame}
										setActiveFrame={setActiveFrame}
										className={getStyles(bf)}
										available={available && variantsAvailable.has(bf)}
									/>
								</Modal.Close>
							))}
						</div>
					</Tabs.Content>
					{!isGB && (
						<Tabs.Content value='kids' removeDefaultStyles className={styles['tab-content']}>
							<div className={styles.grid}>
								{KIDS_BASE_FRAME_NAMES.map(bf => (
									<Modal.Close key={`select-${bf}-kid-shape`} asChild>
										<FrameShapeOption
											frame={bf}
											onClick={callback}
											activeFrame={activeFrame}
											setActiveFrame={setActiveFrame}
											className={getStyles(bf)}
											available={available && variantsAvailable.has(bf)}
										/>
									</Modal.Close>
								))}
							</div>
						</Tabs.Content>
					)}
				</Tabs>
			</Modal.Content>
		</Modal>
	);
};

export default FrameShapeSelect;
